<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader"></div> -->

    <!-- Header Span -->
    <!-- <span class="header-span"></span> -->
    <HeaderBox></HeaderBox>

    <AnnouncementModal 
      :show="showModal"
      :currentAnnouncement="currentAnnouncement"
      :hasPrevious="currentAnnouncementIndex > 0"
      :hasNext="currentAnnouncementIndex < activeAnnouncements.length - 1"
      @close="closeModal"
      @previous="showPreviousAnnouncement"
      @next="showNextAnnouncement"
    />

    <router-view></router-view>

    <section id="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-12" style="margin-right: 70px;">
            <img
              src="/images/ukpetra-logo-brand-text.png"
              class="logo-footer"
              style="margin-bottom: 30px;margin-top: 20px;max-width: 300px;margin-left: -10px;"
            />
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.address') }}</h6>
              <p style="padding-right: 10px;">
                Jl. Siwalankerto No.121-131, Siwalankerto, Kec. Wonocolo, Kota SBY,
                Jawa Timur 60236
              </p>
            </div>
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.contactinfo') }}</h6>
              <p style="padding-right: 10px;">
                <span>T</span>: +62 31 8494830, +62 31 8494831, +62 31 8439040, +62
                31 2983000<br />
                <span>F</span>: +62 31 8436418<br />
                <span>E</span>: info@petra.ac.id<br />
              </p>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.heading.admission') }}</h6>
              <ul>
                <li
                  v-for="(tamp, i) of tampadmission"
                  :key="i"
                >
                  <a :href="tamp.link" target="_blank">{{ tamp.name }}</a>
                </li>
              </ul>
            </div>
            <div class="menu-footer">
              <h6>Petranesian</h6>
              <ul>
                <li
                  v-for="(tamp, i) of tamppetranesian"
                  :key="i"
                >
                  <a :href="tamp.link" target="_blank">{{ tamp.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.heading.tentang') }}</h6>
              <ul>
                <li
                  v-for="(tamp, i) of tampabout"
                  :key="i"
                >
                  <a :href="tamp.link" target="_blank">{{ tamp.name }}</a>
                </li>
              </ul>
            </div>
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.heading.fasilitas') }}</h6>
              <ul>
                <li>
                  <a href="https://petra.ac.id/facilities#library" target="_blank"
                    >{{ $t('frontend.submenus.perpus') }}</a
                  >
                </li>
                <li>
                  <a href="https://petra.ac.id/facilities#health-services" target="_blank"
                    >{{ $t('frontend.submenus.klinik') }}</a
                  >
                </li>
                <li>
                  <a href="https://pkpp.petra.ac.id/" target="_blank"
                    >{{ $t('frontend.submenus.konseling') }}</a
                  >
                </li>
                <li>
                  <a
                    href="https://petra.ac.id/facilities#information-and-communication-technology-center"
                    target="_blank"
                    >{{ $t('frontend.submenus.puskom') }}</a
                  >
                </li>
                <li>
                  <a href="https://petra.ac.id/facilities#career-center" target="_blank"
                    >{{ $t('frontend.submenus.careercenter') }}</a
                  >
                </li>
                <li>
                  <a href="https://petra.ac.id/facilities#baka" target="_blank"
                    >{{ $t('frontend.submenus.kemahasiswaan') }}</a
                  >
                </li>
                <li>
                  <a href="https://petra.ac.id/facilities#international-office--io-" target="_blank"
                    >{{ $t('frontend.submenus.internationaloffice') }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="menu-footer">
              <h6>{{ $t('frontend.footer.heading.fakultas') }}</h6>
              <ul>
                <li
                  v-for="(fak_v,fak_k) in menu.academic"
                  :key="`footer_academic_${fak_k}`"
                >
                  <a :href="`https://petra.ac.id/faculty/${fak_k.split('-')[0]}`" target="_blank">
                    {{ smartPrint(fak_k.split('-')[1]) }}
                  </a>
                </li>
                <li>
                  <a href="http://dmu.petra.ac.id" target="_blank">
                    {{ $t('frontend.submenus.dmu') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="menu-footer">
              <h6>{{ $t('frontend.header.whatsuppetra') }}</h6>
              <ul>
                <li>
                  <a href="https://petra.ac.id/news?menu=1" target="_blank">{{ $t('frontend.submenus.happening') }}</a>
                </li>
                <li>
                  <a href="https://petra.ac.id/news?menu=2" target="_blank">{{ $t('frontend.submenus.highlights') }}</a>
                </li>
                <li>
                  <a href="https://petra.ac.id/news?menu=4" target="_blank">Videos</a>
                </li>
                <li>
                  <a href="https://petra.ac.id/news?menu=9" target="_blank" v-html="$t('frontend.submenus.pressrelease')"></a>
                </li>
                <li>
                  <a href="https://petra.ac.id/news?menu=3" target="_blank">{{ $t('frontend.submenus.archive') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-12" style="margin-right: 70px;margin-bottom: 30px;padding-left: 0;">
            <div class="socialmedia">
              <a href="https://www.facebook.com/pcusurabaya">
                <i class="icon ri-facebook-circle-fill"></i>
              </a>
              <a href="https://www.instagram.com/lifeatpcu/">
                <i class="icon ri-instagram-line"></i>
              </a>
              <a href="https://youtube.com/channel/UCfosIytNyHD-Fbw3rvaxwIw">
                <i class="icon ri-youtube-fill"></i>
              </a>
              <a href="https://www.tiktok.com/@lifeatpcu">
                <div class="icon">
                  <img src="images/tiktokpng.png" />
                </div>
              </a>
            </div>
          </div>
          <div class="col footericonwrapper" style="padding-left: 0;">
            <img
              src="images/best.png"
              class="footericon best"
              style="width:100%;"
            />
            <img
              src="images/ban.png"
              class="footericon ban"
              style="width:100%;"
            />
            <img
              src="images/iabee.png"
              class="footericon iabe"
              style="width:100%;"
            />
            <img
              src="images/merdeka.png"
              class="footericon merdeka"
              style="width:100%;"
            />
          </div>
        </div>
      </div>
      <div class="container copyright">
        <div class="row">
          <div class="col-sm-12 col-md-8" style="padding-left: 0;">
            <small style="font-size: 11px;color:#666"
              >Copyright © 2022 Universitas Kristen Petra.
            </small>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- End Page Wrapper -->
</template>
<script>
import HeaderBox from "./component/Header";
import axios from "axios";
import { AnnouncementServices } from "@/services/ApiServices/AnnouncementService";
import AnnouncementModal from "../AnnouncementModal.vue";


export default {
  components: {
    HeaderBox,
    AnnouncementModal
  },
  data: () => ({
    menu: {
      academic: []
    },
    admissionen: [
      {
        "name": "How to Apply",
        "link": "https://petra.ac.id/admission#caradaftar"
      },
      {
        "name": "Scholarships",
        "link": "https://petra.ac.id/admission#beasiswa"
      },
      {
        "name": "Admission Result",
        "link": "https://admission.petra.ac.id/index.php/site/pengumuman"
      },
      {
        "name": "Transcript Simulation",
        "link": "https://petra.ac.id/admission-simulation"
      },
      {
        "name": "FAQ",
        "link": "https://petra.ac.id/admission#faq"
      }
    ],
    admissionid: [
      {
        "name": "Jalur Pendaftaran",
        "link": "https://petra.ac.id/admission#caradaftar"
      },
      {
        "name": "Beasiswa",
        "link": "https://petra.ac.id/admission#beasiswa"
      },
      {
        "name": "Informasi Penerimaan",
        "link": "https://admission.petra.ac.id/index.php/site/pengumuman"
      },
      {
        "name": "Simulasi Rapor",
        "link": "https://petra.ac.id/admission-simulation"
      },
      {
        "name": "FAQ",
        "link": "https://petra.ac.id/admission#faq"
      }
    ],
    petranesianen: [
      {
        "name": "Important Dates",
        "link": "https://petra.ac.id/important-dates"
      },
      {
        "name": "Events",
        "link": "https://petra.ac.id/events"
      }
    ],
    petranesianid: [
      {
        "name": "Tanggal Penting",
        "link": "https://petra.ac.id/important-dates"
      },
      {
        "name": "Acara",
        "link": "https://petra.ac.id/events"
      },
    ],
    abouten: [
      {
        "name": "About",
        "link": "https://petra.ac.id/about#about"
      },
      {
        "name": "Petra in Numbers",
        "link": "https://petra.ac.id/about#number"
      },
      {
        "name": "Vision Mission",
        "link": "https://petra.ac.id/about#visimisi"
      },
      {
        "name": "From Our Rector",
        "link": "https://petra.ac.id/about#foreword"
      },
      {
        "name": "YPTK Petra",
        "link": "https://petra.ac.id/about#yptk"
      },
      {
        "name": "Partners",
        "link": "https://petra.ac.id/about#partners"
      },
      {
        "name": "Infographics",
        "link": "https://petra.ac.id/infographics"
      }
    ],
    aboutid: [
      {
        "name": "Tentang Petra",
        "link": "https://petra.ac.id/about#about"
      },
      {
        "name": "Petra dalam Angka",
        "link": "https://petra.ac.id/about#number"
      },
      {
        "name": "Visi Misi",
        "link": "https://petra.ac.id/about#visimisi"
      },
      {
        "name": "Pesan Rektor",
        "link": "https://petra.ac.id/about#foreword"
      },
      {
        "name": "YPTK Petra",
        "link": "https://petra.ac.id/about#yptk"
      },
      {
        "name": "Rekanan",
        "link": "https://petra.ac.id/about#partners"
      },
      {
        "name": "Infografis",
        "link": "https://petra.ac.id/infographics"
      }
    ],
    tampadmission: [],
    tamppetranesian: [],
    tampabout: [],
    showModal: false,
    announcements: [],
    activeAnnouncements: [],
    currentAnnouncementIndex: 0,
  }),
  mounted() {
    this.loadAcademicMenu();
  },
  computed: {
    userState: function () {
      return this.$store.state.user;
    },
    currentAnnouncement() {
      return this.activeAnnouncements[this.currentAnnouncementIndex] || {};
    },
  },
  watch: {
    '$route': {
      handler: 'getAnnouncement',
      immediate: true
    }
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    smartPrint: str => (str != null && typeof str != "undefined" ? str : ""),
    loadAcademicMenu() {
      if(localStorage.getItem('locale') == 'en'){
        this.tampadmission = this.admissionen;
        this.tamppetranesian = this.petranesianen;
        this.tampabout = this.abouten;
      }else if(localStorage.getItem('locale') == 'id'){
        this.tampadmission = this.admissionid;
        this.tamppetranesian = this.petranesianid;
        this.tampabout = this.aboutid;
      }
      
      axios
        .get("https://serviceomni.petra.ac.id/api/v1/frontend/menu/academic")
        .then(response => {
          var data = response.data.result;
          this.menu.academic = data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAnnouncement(){
      const announcementServices = new AnnouncementServices();
      const currentRoute = this.$route.name;
      const stages = ['Stage-1', 'Stage-2', '/stage-3', '/stage-4', '/stage-5', '/stage-6'];
  
      const currentStage = stages.find(stage => currentRoute.includes(stage));
      
      if (currentStage) {
        // Extract the number from the stage
        const stageNumber = currentStage.split('-')[1];
        
        announcementServices.get(stageNumber).then((response) => {
          this.announcements = response;

          this.activeAnnouncements = response;
          if (this.activeAnnouncements?.length > 0) {
            this.showModal = true;
          }
        });
      }
    },
    showPreviousAnnouncement() {
      if (this.currentAnnouncementIndex > 0) {
        this.currentAnnouncementIndex--;
      }
    },
    showNextAnnouncement() {
      if (this.currentAnnouncementIndex < this.activeAnnouncements.length - 1) {
        this.currentAnnouncementIndex++;
      }
    },
    closeModal() {
      this.showModal = false;
      this.currentAnnouncementIndex = 0;
    },
  },
};
</script>
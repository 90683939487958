import $axios, { END_POINTS } from '@/api'


export class AnnouncementServices {
    async get(stage){
        const response = await $axios.get(`${END_POINTS.announcement.base}?stage=${stage}`)
        response.isOk = response.status >= 200 && response.status < 300
        if (!response.isOk || response.status >= 500) {
          const error = new Error(response.message)
          error.response = response.response
          throw error
        }
    
        return response.data
    }

    async ack(data){
        const response = await $axios.post(`${END_POINTS.announcement.base}`,data)
        response.isOk = response.status >= 200 && response.status < 300
        if (!response.isOk || response.status >= 500) {
        const error = new Error(response.message)
        error.response = response.response
        throw error
        }

        return response.data
    }
}
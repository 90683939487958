<template>
    <transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">
              <div v-html="currentAnnouncement.content" @click="openLink(currentAnnouncement?.link)"></div>
            </div>
            <div class="modal-footer">
              <div class="modal-nav-buttons">
                <button 
                  v-if="hasPrevious" 
                  @click="$emit('previous')" 
                  class="modal-nav-button prev"
                >
                   Previous
                </button>
                <button 
                  v-if="hasNext" 
                  @click="$emit('next')" 
                  class="modal-nav-button next"
                >
                  Next
                </button>
              </div>
              <button class="modal-default-button" @click="$emit('close')">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'AnnouncementModal',
    props: {
      show: Boolean,
      currentAnnouncement: Object,
      hasPrevious: Boolean,
      hasNext: Boolean,
    },
    methods: {
      openLink(url) {
        if(url){
          window.open(url, '_blank');
        }
      },
    }
  }
  </script>
  
  <style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    transition: opacity .3s ease;
  }
  
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .modal-container {
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    margin: 0px auto;
    padding: 0px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 0px;
  }
  
  .modal-header h3 {
    margin: 0;
    color: #333;
  }
  
  .modal-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  .modal-body {
    margin: 0px 0;
    overflow-y: auto;
    max-height: calc(90vh - 200px);
    cursor: pointer;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
  }
  
  .modal-nav-buttons {
    display: flex;
  }
  
  .modal-nav-button {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .modal-nav-button:hover {
    background-color: #e0e0e0;
  }
  
  .modal-nav-button.prev::before {
    content: '\2190'; /* Left arrow */
    margin-right: 5px;
  }
  
  .modal-nav-button.next::after {
    content: '\2192'; /* Right arrow */
    margin-left: 5px;
  }
  
  .modal-default-button {
    background-color: #1e3258;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: scale 0.3s;
  }
  
  .modal-default-button:hover {
    scale: 1.1;
  }
  
  .modal-enter-from, .modal-leave-to {
    opacity: 0;
  }
  
  .modal-enter-from .modal-container,
  .modal-leave-to .modal-container {
    transform: scale(1.1);
  }
  </style>
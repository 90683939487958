<template>
  <div class="logo-box" style="margin-right: auto">
    <div class="logo">
      <a href="/">
        <img
          src="/images/logo-ukp-brand-white.png"
          v-if="!isHover && isAuth && !isMobile"
        />
        <img
          src="/images/logo-ukp-brand.png"
          :class="[{ 'tw-invert': isBlackLogo() }]"
          v-if="isHover || !isAuth || isMobile"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoBox',
  props: ['color', 'isHover', 'isAuth'],
  data() {
    return {
      isMobile: false,
      isScrolled: false
    }
  },
  created() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
    window.addEventListener('scroll', this.checkScroll)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile)
    window.removeEventListener('scroll', this.checkScroll)
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },
    checkScroll() {
      this.isScrolled = window.scrollY > 20 // Adjust threshold as needed
    },
    isBlackLogo() {
      if (this.isAuth == false) {
        return true
      } else {
        if (this.isAuth == true && this.isMobile) {
          return true
        }
      }
      if (this.isHover) {
        return true
      }
      if (this.isScrolled == true) {
        return true
      }

      return false
    }
  }
}
</script>

export const PETRA_TEST_SERVICES = process.env.VUE_APP_PETRA_TEST_SERVICES
export const PETRA_TOKEN_SERVICES = process.env.VUE_APP_PETRA_TOKEN_SERVICES
export const PETRA_APP_ID = process.env.VUE_APP_PETRA_APP_ID
export const PETRA_APP_SECRET = process.env.VUE_APP_PETRA_APP_SECRET
export const PETRA_QUIZ_URL = process.env.VUE_APP_PETRA_QUIZ_URL
export const PETRA_RPL_PREVIEW = process.env.VUE_APP_RPL_DOCS_PREVIEW

export const ROUTE_KEYS = {
  AUTHED_ROUTE: {
    stage_1: '/stage-1',
    stage_1_rpl: '/stage-1-rpl',
    stage_2: '/stage-2',
    stage_3: '/stage-3',
    stage_4_quiz: '/stage-3-quiz',
    quiz: '/quiz',
    quiz_student: '/quiz-student',
    stage_4: '/stage-4',
    stage_5: '/stage-5',
    stage_6: '/stage-6',
    stage_7: '/stage-7',
    konsultasi: '/konsultasi',
    wawancara: '/wawancara',
    dokumen_matkul: '/formulir-evaluasi-diri',
    dokumen_matkul_v2: '/formulir-evaluasi-diri-v2'
  },
  NON_MAHASISWA_ROUTE: {
    review_external: '/review-external'
  }
}

export const STAGES = [
  {
    id: 1,
    stage: 'main.home.stage1',
    title: 'main.home.registration',
    icon: 'fa fa-light fa-graduation-cap',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_1,
    key: 'form_1'
  },
  {
    id: 2,
    stage: 'main.home.stage2',
    title: 'main.home.adminselect1',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_2,
    key: 'form_2'
  },
  {
    id: 3,
    stage: 'main.home.stage3',
    title: 'main.home.selectionresult',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_3,
    key: 'seleksi'
  },
  {
    id: 4,
    stage: 'main.home.stage4',
    title: 'main.home.adminselect2',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_4,
    key: 'form_3'
  },
  {
    id: 5,
    stage: 'main.home.stage5',
    title: 'main.home.adminkeuangan',
    icon: 'fa fa-light fa-credit-card',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_5,
    key: 'administrasi_keuangan'
  },
  {
    id: 6,
    stage: 'main.home.stage6',
    title: 'main.home.definitive',
    icon: 'fa fa-light fa-user-check',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_6,
    key: 'definitif'
  }
]

export const STAGES_RPL = [
  {
    id: 1,
    stage: 'main.home.stage1',
    title: 'main.home.registration',
    icon: 'fa fa-light fa-graduation-cap',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_1_rpl,
    key: 'form_1'
  },
  {
    id: 2,
    stage: 'main.home.stage2',
    title: 'main.home.adminselect1',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_2,
    key: 'form_2'
  },
  {
    id: 3,
    stage: 'main.home.stage3',
    title: 'main.home.Formulir Evaluasi Diri',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.dokumen_matkul,
    key: 'seleksi'
  },
  {
    id: 4,
    stage: 'main.home.stage4',
    title: 'main.home.adminselect2',
    icon: 'fa fa-light fa-book-open-reader',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_4,
    key: 'form_3'
  },
  {
    id: 5,
    stage: 'main.home.stage5',
    title: 'main.home.adminkeuangan',
    icon: 'fa fa-light fa-credit-card',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_5,
    key: 'administrasi_keuangan'
  },
  {
    id: 6,
    stage: 'main.home.stage6',
    title: 'main.home.definitive',
    icon: 'fa fa-light fa-user-check',
    to: ROUTE_KEYS.AUTHED_ROUTE.stage_6,
    key: 'definitif'
  }
]

export const ADMISSION_TESTS = [
  {
    id: 1,
    title: 'Academic Test',
    card_color: '#E8EEFF',
    quiz_time: 60
  },
  {
    id: 2,
    title: 'Drawing Test',
    card_color: '#EEE5CE',
    quiz_time: 15
  },
  {
    id: 3,
    title: 'Interview Session',
    card_color: '#D4EFEB',
    quiz_time: 0
  },
  {
    id: 4,
    title: 'English Proficient Test',
    card_color: '#F7E1D5',
    quiz_time: 0
  },
  {
    id: 5,
    title: 'Logic Test',
    card_color: '#EFE1FB',
    quiz_time: 0
  }
]

export const QUIZ_FLOW = {
  1: {
    id: 1,
    name: 'PARALLEL'
  },
  2: {
    id: 2,
    name: 'SERIAL'
  }
}

export const ADMISSION_TEST_RECORD = {
  1: {
    id: 1,
    title: 'Drawing Test',
    card_color: '#EEE5CE',
    quiz_time: 0
  },
  2: {
    id: 2,
    title: 'Interview Session',
    card_color: '#D4EFEB',
    quiz_time: 0
  },
  3: {
    id: 3,
    title: 'Toefl Test',
    card_color: '#EFE1FB',
    quiz_time: 0
  },
  4: {
    id: 4,
    title: 'Academic English',
    card_color: '#E8EEFF',
    quiz_time: 60
  },
  5: {
    id: 5,
    title: 'Academic Math',
    card_color: '#E8EEFF',
    quiz_time: 60
  },
  6: {
    id: 5,
    title: 'Logic Test',
    card_color: '#EEE5CE',
    quiz_time: 60
  },
  7: {
    id: 7,
    title: 'Tes CBT',
    card_color: '#EEE5CE',
    quiz_time: 0
  },
  8: {
    id: 8,
    title: 'Tes Kesehatan',
    card_color: '#EEE5CE',
    quiz_time: 0
  },
  9: {
    id: 9,
    title: 'Tes Psikotes',
    card_color: '#EEE5CE',
    quiz_time: 0
  },
  10: {
    id: 10,
    title: 'Pembayaran',
    card_color: '#EEE5CE',
    quiz_time: 0
  }
}

export const GOLONGAN_DARAH = [
  {
    id: 'A',
    nama: 'A'
  },
  {
    id: 'B',
    nama: 'B'
  },
  {
    id: 'AB',
    nama: 'AB'
  },
  {
    id: 'O',
    nama: 'O'
  }
]

export const CLASS_CODE_TO_NAME = {
  QF: 'QUALIFIED',
  HD: 'HIGH DISTINCTION',
  DT: 'DISTINCTION'
}

export const UKURAN_OPTION = [
  { id: 'S', nama: 'S' },
  { id: 'M', nama: 'M' },
  { id: 'L', nama: 'L' },
  { id: 'XL', nama: 'XL' },
  { id: 'XXL', nama: 'XXL' },
  { id: 'XXXL', nama: 'XXXL' },
  { id: '4XL', nama: '4XL' },
  { id: '5XL', nama: '5XL' },
  { id: '8XL', nama: '8XL' }
]

// export const KODE_UNIT_KEDOKTERAN = '101'
export const KODE_UNIT_KEDOKTERAN_UMUM = '62'
export const KODE_UNIT_KEDOKTERAN_GIGI = '63'

export const TEST_KEDOKTERAN_DATE_CONFIG = {
  TEST_CBT_DATE: '1 Agustus 2024',
  TEST_CBT_ANNOUNCEMENT_DATE: '1 Agustus 2024',
  PAYMENT_DATE_MAX: '1 - 2 Agustus 2024 pukul 18.00 WIB',
  TEST_PSIKOTES: '2 Agustus 2024',
  TEST_KESEHATAN: '3 Agustus 2024',
  TEST_WAWANCARA: '7 - 8 Agustus 2024'
}

export const DESKRIPSI_QUIZ_KEDOKTERAN = {
  TEST_CBT: `
  <div>
  Terima kasih telah mendaftar di Program Studi Kedokteran Universitas Kristen Petra
  <br/>
  Proses seleksi pertama adalah Tes Potensi Akademik CBT (<i>Computer Based Test</i>) yang meliputi Bahasa Indonesia, Logika, Matematika, Bahasa Inggris, dan Biologi.
  <br/>
  Mohon hadir dalam TPA CBT pada:
  <br/>
  Tanggal: <b> ${TEST_KEDOKTERAN_DATE_CONFIG.TEST_CBT_DATE}</b>
  <br/>

  Waktu: <b>09.00 - 11.00 WIB</b>
  <br/>
  <b>
  (mohon hadir 30 menit sebelum sesi TPA CBT berlangsung untuk melakukan
    registrasi dan verifikasi data dengan membawa KTP/KIA)
  </b>
  <br/> <br/>
  Lokasi tes TPA CBT:
  <br/>
  Laboratorium CBT Fakultas Kedokteran UK Petra
  <br/>
  Gedung Q Lt. 2 Kampus Timur UK Petra
  <br/>
  Jalan Siwalankerto No 121 - 131 Surabaya
  <br/>
  Google maps:
  <br/>
  https://maps.app.goo.gl/DVz1XnQfX7T5gRfP6
  <br/>
  <br/>
  Pengumuman TPA CBT akan disampaikan melalui website, e-mail, dan WhatsApp Resmi FK UK Petra pada tanggal ${TEST_KEDOKTERAN_DATE_CONFIG.TEST_CBT_ANNOUNCEMENT_DATE}.
  <br/>
  <br/>
  Jika membutuhkan informasi lebih lanjut mengenai TPA CBT, kamu dapat
menghubungi (Admisi kedokteran: +62 811-3277-123 atau +62 811-3070-0510)
  </div>
  `,
  PEMBAYARAN: `
  <div>
    <b>Congratulation!</b><br>
    Kamu dinyatakan lulus Tes Potensi Akademik CBT(<i>Computer Based Test</i>) Fakultas Kedokteran UK Petra!<br><br>

    <b>Tahap selanjutnya adalah Psikotes dan Tes Kesehatan</b><br>
    Silakan melakukan pembayaran sebesar Rp 1.200.000,00 ke rekening berikut ini:<br>
    - Bank Mandiri a/n YPTK Petra 142 00 000 88988<br>
    - Bank BNI a/n YPTK Petra 8555977779<br>
    Berita Transaksi: Nama dan No. Ujian<br>
    Paling lambat ${TEST_KEDOKTERAN_DATE_CONFIG.PAYMENT_DATE_MAX}<br>
    Bukti pembayaran silakan di unggah pada link berikut ini dan dikirimkan ke WA Admisi 2: +62 811-3070-0510)<br><br>

    Terima kasih dan silakan menuju quiz serial berikutnya untuk informasi lebih lanjut mengenai psikotes dan tes kesehatan.<br>
    Jika membutuhkan informasi lebih lanjut kamu dapat menghubungi (Admisi kedokteran: +62 811-3277-123 atau  +62 811-3070-0510)
</div>

  `,
  TEST_PSIKOTES: `
  <div>
    Terima kasih telah menyelesaikan tahap seleksi Tes Potensi Akademik CBT(<i>Computer Based Test</i>)<br><br>
    <b>Proses seleksi berikutnya adalah Psikotes</b><br>
    Mohon hadir dalam Psikotes pada:<br>
    <b>Tanggal:</b> ${TEST_KEDOKTERAN_DATE_CONFIG.TEST_PSIKOTES}<br>
    <b>Waktu:</b> 07.30 - 17.00 WIB (disediakan makan siang)<br>
    Mohon hadir 30 menit sebelumnya dengan membawa KTP/KIA dan surat referensi yang dapat diunduh pada tautan di bawah ini (Wajib ditunjukkan saat proses verifikasi data).<br><br>


    <b>Lokasi Psikotes:</b><br>
    Laboratorium CBT Fakultas Kedokteran UK Petra<br>
    Gedung Q Lt. 2 Kampus Timur UK Petra<br>
    Jalan Siwalankerto No 121 - 131 Surabaya<br>
    Google maps: <br>
https://maps.app.goo.gl/DVz1XnQfX7T5gRfP6
    <br><br>
    Jika membutuhkan informasi lebih lanjut kamu dapat menghubungi (Admisi kedokteran: +62 811-3277-123 atau  +62 811-3070-0510)
</div>


`,

  TEST_KESEHATAN: `
<div>
  Terima kasih telah menyelesaikan tahap seleksi Psikotes<br><br>
  <b>Proses seleksi berikutnya adalah Tes Kesehatan</b><br>
  Mohon hadir dalam Tes Kesehatan pada salah satu tanggal berikut ini:<br>
  <b>Tanggal:</b> ${TEST_KEDOKTERAN_DATE_CONFIG.TEST_KESEHATAN}<br>
  <b>Waktu:</b> mulai pukul 08.00 - 10.00 WIB <br>
  Mohon hadir 30 menit sebelumnya dengan membawa KTP/KIA dan surat referensi yang dapat diunduh pada tautan di bawah ini (Wajib ditunjukkan saat proses verifikasi data).<br><br>


  <b>Lokasi Tes Kesehatan:</b><br>
  Mayapada Hospital Surabaya<br>
  Jl. Mayjen Sungkono No.20, Pakis, Kec. Sawahan, Surabaya, Jawa Timur 60256<br>
  Google maps: <br>
  https://maps.app.goo.gl/FMmc3VmkjDBHrY1J6
  <br><br>


  <b>Notes:</b><br>
  Hasil tes kesehatan akan diberikan dalam bentuk <i> hard copy</i> setelah selesai <i> interview session </i> di UK Petra. Dengan mengikuti tes kesehatan, kamu dianggap menyetujui ketentuan tersebut di atas.<br><br>

  Jika membutuhkan informasi lebih lanjut kamu dapat menghubungi (Admisi kedokteran: +62 811-3277-123 atau  +62 811-3070-0510)
</div>
`,
  TEST_INTERVIEW: `
  <div>
    Terima kasih telah menyelesaikan tahap seleksi Tes Kesehatan<br><br>

    <b>Proses seleksi berikutnya adalah Wawancara.</b><br>
    Mohon hadir bersama dengan orang tua/wali dalam sesi wawancara pada:<br>
    <b>Tanggal:</b> ${TEST_KEDOKTERAN_DATE_CONFIG.TEST_WAWANCARA}<br>
    <b>Waktu:</b> 10.00 WIB s.d selesai<br>
    <b>Lokasi wawancara:</b><br>
    Ruang PBL 1 Fakultas Kedokteran UK Petra<br>
    Gedung Q Lt 2 Kampus Timur UK Petra<br>
    Jalan Siwalankerto No 121 - 131 Surabaya<br><br>

    Silakan unduh jadwal wawancaramu pada tautan berikut. Mohon hadir 30 menit sebelumnya dengan membawa KTP/KIA (Wajib ditunjukkan saat proses verifikasi data).<br><br>

    Jika membutuhkan informasi lebih lanjut kamu dapat menghubungi (Admisi kedokteran: +62 811-3277-123 atau  +62 811-3070-0510)
</div>

`
}

export const ValidYear = ['2024', '2025']

<template>
  <span
    v-if="
      urlNow.value?.name != 'HomePage' &&
      urlNow.value?.name != 'Login Page' &&
      urlNow.value?.name != 'Forgot Password'
    "
    class="header-span"
  ></span>
  <!-- Main Header-->
  <header
    class="main-header main-header-auth navbare"
    :style="isMobile ? { background: 'white' } : {}"
    @mouseover="mouseOver"
    @mouseleave="mouseOut"
  >
    <!-- Main box -->
    <div class="nav-outer desktopnav" :class="{ active: mobile_sidebar }">
      <Logo
        :color="colorspan"
        :isHover="isHover"
        :isAuth="
          urlNow.value.name == 'Login Page' ||
          urlNow.value.name == 'Forgot Password'
        "
      />

      <ul class="nav navigation mainnav">
        <li style="align-items: center; display: flex" class="langselector">
          <p style="margin-bottom: 0">
            <span
              :class="{ active: $i18n.locale == 'id' }"
              @click="changeLanguage('id')"
              :style="{ color: colorspan }"
              >ID</span
            >
            |
            <span
              :class="{ active: $i18n.locale == 'en' }"
              @click="changeLanguage('en')"
              :style="{ color: colorspan }"
              >EN</span
            >
          </p>
        </li>
      </ul>

      <div
        :id="[
          urlNow.value.name == 'Login Page' ||
          urlNow.value.name == 'Forgot Password'
            ? 'menu-toggle-login'
            : 'menu-toggle'
        ]"
        href="javascript:void(0)"
        @click="mobile_sidebar = !mobile_sidebar"
        :class="{ open: mobile_sidebar }"
      >
        <div id="hamburger">
          <span :style="{ background: colorspan }"></span>
          <span :style="{ background: colorspan }"></span>
          <span :style="{ background: colorspan }"></span>
        </div>
        <div id="cross">
          <span></span>
          <span></span>
        </div>
      </div>

      <!-- Main Menu End-->
    </div>
    <div class="mobilemenuwrapper" :class="{ active: mobile_sidebar }">
      <ul class="mobilemenu" :class="{ active: mobile_sidebar }">
        <!-- <li v-if="userState">
          <a @click="toProfile">
            {{ $t("menu.mobile.account") }}
          </a>
        </li> -->
        <li v-if="userState">
          <a @click="logout">{{ $t('menu.sign_out') }}</a>
        </li>
        <li
          style="align-items: center; display: flex"
          class="langselector mobile"
        >
          <p style="margin-bottom: 0">
            <span
              :class="{ active: $i18n.locale == 'id' }"
              @click="changeLanguage('id')"
              >ID</span
            >
            |
            <span
              :class="{ active: $i18n.locale == 'en' }"
              @click="changeLanguage('en')"
              >EN</span
            >
          </p>
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
import { deleteAllCookies } from '@/helpers/cookies_helper'
import menuList from '@/static/menu'
import Logo from './Logo.vue'

export default {
  name: 'HeaderBox',
  components: {
    Logo
  },
  data: () => ({
    isLoggedIn: false,
    user: {},
    mobile_sidebar: false,
    base_url: process.env.VUE_APP_BASE_URL,
    colorspan: null,
    isHover: false,
    isMobile: false
  }),
  watch: {
    $route() {
      this.checkHeaderColor()
    }
  },
  computed: {
    console: () => console,
    window: () => window,
    userState: function () {
      return this.$store.state.user
    },
    menu: function () {
      return menuList.public
    },
    urlNow: function () {
      return this.$router.currentRoute
    }
  },
  created() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile)
  },
  mounted() {
    this.user = this.$store.state.user
    this.checkAuth()
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },
    checkAuth: function () {
      if (this.$store.state.user && localStorage.getItem('token')) {
        this.isLoggedIn = true
      } else {
        this.isLoggedIn = false
      }
      this.checkHeaderColor()
    },
    checkHeaderColor() {
      if (this.urlNow.value.name != 'HomePage') {
        this.colorspan = 'black'
      }
    },
    toProfile() {
      this.mobile_sidebar = !this.mobile_sidebar
      this.$router.push('/profile')
    },
    logout() {
      this.$swal({
        title: this.$t('logout.title'),
        text: this.$t('logout.text'),
        icon: 'warning',
        showCancelButton: true,
        buttons: true,
        dangerMode: true
      }).then(async (value) => {
        if (value.isConfirmed) {
          this.$store.commit('updateUser', null)
          localStorage.removeItem('token')
          localStorage.removeItem('user')

          delete this.$axios.defaults.headers.Authorization
          deleteAllCookies()

          this.checkAuth()

          this.$router.push('/login')
        }
        return
      })
    },
    changeLanguage(lang) {
      this.$store.commit('updateLocale', lang)
      location.reload()
    },
    mouseOver: function () {
      this.colorspan = 'black'
      this.isHover = true
    },
    mouseOut: function () {
      if (this.urlNow.value.name != 'HomePage') {
        // this.colorspan = "black";
        this.isHover = false
      } else {
        this.isHover = true
        this.colorspan = null
      }
    }
  }
}
</script>
